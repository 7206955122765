import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Link } from 'gatsby';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { MenuContext } from '../templates/contexts';
import { dispatch } from 'use-bus';

import { faRightFromBracket, faUserVneck } from '@fortawesome/pro-solid-svg-icons';
import { observeStorage, useStorage } from '../templates/localStorageContext';
import styled from 'styled-components';

const LoginButton = styled.button`
  appearance: none;
  border: none;
  background: none;
  padding: 0;
  margin: 0 10px 0 20px;
  color: #fff;
  font-size: 1.1rem;
  transition: color 0.2s ease-in-out;
  svg {
    margin-left: 20px;
    margin-right: 10px;
    * {
      fill: #fff;
      transition: fill 0.2s ease-in-out;
    }
  }

  &:hover {
    color: #cbd6e2;
    svg {
      * {
        fill: #cbd6e2;
      }
    }
  }
  @media only screen and (max-width: 680px) {
    display: none;
  }
`;

const TopNav = observeStorage(() => {
  const customMenu = useContext(MenuContext);
  const storage = useStorage();

  const topNavData = useStaticQuery(graphql`
    query topNavQuery {
      nimbuMenu(slug: { eq: "topnav" }) {
        id
        items {
          target_url
          name
        }
      }
    }
  `);

  function handleEnterSearch(event) {
    if (event.key === 'Enter') {
      var currentUrl = window.location.origin;
      var param = document.getElementById('search-input').value;
      var url = currentUrl + '/search?search_query=' + param;
      window.location.href = url;
    }
  }
  return (
    <div
      className={classNames('topnav-wrapper d-print-none', {
        [`topnav-${customMenu?.slug}`]: customMenu?.slug != null,
      })}
    >
      <div className="container">
        <div className="row">
          <div id="menu" className="col-md-12 d-flex justify-content-end align-items-center">
            <ul className="topnav">
              {topNavData.nimbuMenu.items.map((data, index) => (
                <li key={index}>
                  <Link to={`${data.slug === 'homepage' ? '/' : data.target_url}`}>
                    {data.name}
                  </Link>
                </li>
              ))}
            </ul>

            {storage.isLoggedIn ? (
              <LoginButton onClick={() => dispatch('logout')}>
                <FontAwesomeIcon icon={faRightFromBracket} />
              </LoginButton>
            ) : (
              <LoginButton className="header-login-button" onClick={() => dispatch('showLogin')}>
                <FontAwesomeIcon icon={faUserVneck} />
                Aanmelden
              </LoginButton>
            )}

            <div className="search-box">
              <input
                className="search-input"
                id="search-input"
                type="text"
                name=""
                placeholder="Zoek en druk enter"
                onKeyPress={handleEnterSearch}
              ></input>
              <Button className="search-btn" variant="link">
                <FontAwesomeIcon icon={faSearch} />{' '}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default TopNav;
