import { createContext, useContext } from 'react';

export const MenuContext = createContext();
export const TranslationsContext = createContext();

export function useMenu() {
  return useContext(MenuContext);
}

export function useTranslations() {
  return useContext(TranslationsContext);
}
