import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { Link } from 'gatsby';
import logo from '../images/logo.svg';
import { Nav, Navbar } from 'react-bootstrap';
import classNames from 'classnames';
import { useContext } from 'react';
import { MenuContext } from '../templates/contexts';

//TODO: add mobile options.

const NavLink = styled(Link)`
  flex: 0 0 auto;
  display: inline-block;
  line-height: 3.125rem;
  transition: opacity 0.2s, transform 0.2s;
  cursor: pointer;
  letter-spacing: 0.025rem;
  color: currentColor;
  &:hover,
  &:focus {
    opacity: 0.8;
  }
  &:active {
    transform: scale(0.95);
    opacity: 0.6;
  }
`;

export default function NavHeader() {
  const customMenu = useContext(MenuContext);

  const navData = useStaticQuery(graphql`
    query navQuery {
      nimbuMenu {
        id
        items {
          target_url
          name
        }
      }
    }
  `);

  const menuItems = customMenu != null ? customMenu.items : navData.nimbuMenu.items;

  return (
    <header
      className={classNames(
        'header-nav',
        'd-print-none',
        customMenu?.slug ? `menu-${customMenu?.slug}` : undefined
      )}
    >
      <Navbar collapseOnSelect expand="lg" className="">
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img alt="Stoppen is Mogelijk!" width="250" className="logo" src={logo} />
          </Link>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto"></Nav>
            <Nav className="mainnav">
              <ul className="nav-items">
                {menuItems.map((data, idx) => (
                  <li key={idx}>
                    <NavLink to={`${data.slug === 'homepage' ? '/' : data.target_url}`}>
                      {data.name}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </header>
  );
}
