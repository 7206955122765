import { EncryptStorage } from 'encrypt-storage';
import React, { createContext, useContext, useEffect, useState } from 'react';

const DEBUG = false;

// The key used to store the data in the localStorage.
const STORAGE_KEY = 'data';

// The secretKey parameter must bne contains min 10 characters. So we prefix it with a fixed string.
const ENCRYPTION_PREFIX = 'stoppen-is-mogelijk';

class ExercisesStorage {
  constructor() {
    this.data = {};
    this.changes = {};
    this.callbacks = {};
    this.version = 0;
  }

  setItem(key, value) {
    this.changes[key] = value;
    this.triggerCallback('change');
  }

  setProgress(key, value) {
    this.data.progress = this.data.progress ?? {};
    this.data.progress[key] = value;
    this.storage?.setItem(STORAGE_KEY, this.data);
  }

  markChapterAsRead(chapterId) {
    this.setProgress(`chapter-${chapterId}`, true);
  }

  isRead(chapterId) {
    return this.data.progress?.[`chapter-${chapterId}`] ?? false;
  }

  setItems(data) {
    this.changes = { ...this.changes, ...data };
    this.triggerCallback('change');
  }

  getItem(key) {
    return this.changes[key] ?? this.data[key];
  }

  setPassword(password) {
    this.storage = new EncryptStorage(`${ENCRYPTION_PREFIX}-${password}`, {
      notifyHandler: DEBUG ? (params) => console.info({ params }) : undefined,
    });
  }

  get isLoggedIn() {
    return !!this.storage;
  }

  get isRegistered() {
    return typeof window !== 'undefined' && !!localStorage.hasOwnProperty(STORAGE_KEY);
  }

  load() {
    try {
      this.data = { ...this.data, ...this.changes, ...(this.storage?.getItem(STORAGE_KEY) ?? {}) };
      this.changes = {};
      this.version++;
    } catch {
      // ignore, probably wrong password
    }
  }

  async login(password) {
    this.setPassword(password);

    if (this.isRegistered) {
      this.load();

      if (this.data?.version == null) {
        this.storage = undefined;

        return false;
      }
    }

    this.save();
    this.triggerCallback('load');

    return true;
  }

  logout() {
    this.data = {};
    this.changes = {};
    this.storage = undefined;

    // trigger a refresh
    this.version++;
    this.triggerCallback('load');
  }

  save() {
    const data = { ...this.data, ...this.changes, version: this.version };
    this.storage?.setItem(STORAGE_KEY, data);
    this.data = data;
    this.changes = {};
    this.triggerCallback('save');
  }

  clear() {
    this.storage?.clear();
    localStorage.removeItem(STORAGE_KEY);
    this.logout();
  }

  registerCallback(type, callback) {
    const randomKey = Math.random().toString(36).substring(2);
    this.callbacks[type] = this.callbacks[type] ?? {};
    this.callbacks[type][randomKey] = callback;

    return () => {
      delete this.callbacks[type][randomKey];
    };
  }

  triggerCallback(type) {
    if (Object.keys(this.callbacks[type] ?? {}).length > 0) {
      for (const callback of Object.values(this.callbacks[type])) {
        callback(this);
      }
    }
  }

  onReset(callback) {
    return this.registerCallback('reset', callback);
  }

  get canReset() {
    return this.callbacks['reset'] && Object.keys(this.callbacks['reset']).length > 0;
  }

  reset() {
    if (this.canReset) {
      this.triggerCallback('reset');
    }
  }

  onChange(callback) {
    return this.registerCallback('change', callback);
  }

  onLoad(callback) {
    return this.registerCallback('load', callback);
  }

  onLogout(callback) {
    return this.registerCallback('logout', callback);
  }

  onLogin(callback) {
    return this.registerCallback('login', callback);
  }

  onSave(callback) {
    return this.registerCallback('save', callback);
  }

  get isDirty() {
    if (Object.keys(this.changes).length === 0) return false;

    for (const key of Object.keys(this.changes)) {
      if (this.changes[key] !== this.data[key]) {
        return true;
      }
    }

    return false;
  }

  get dataWithChanges() {
    return { ...this.data, ...this.changes };
  }
}

export const storage = new ExercisesStorage();
export const LocalStorageContext = createContext(storage);

export function useStorage() {
  return useContext(LocalStorageContext);
}

export const observeStorage = (Component) => (props) => {
  const storage = useStorage();
  const [_, setValue] = useState(0);

  function useForceUpdate() {
    setValue((value) => value + 1);
  }

  useEffect(() => {
    if (!storage) return;

    const unsubscribe = storage.onLoad(useForceUpdate);
    return () => unsubscribe();
  }, [storage, setValue]);

  return <Component {...props} key={storage.version} />;
};

export function useOnStorageEvent(event, callback) {
  const storage = useStorage();

  useEffect(() => {
    if (!callback || !storage) return;

    const unsubscribe = storage[event](callback);
    return () => unsubscribe();
  }, [storage, callback, event]);
}

export function useOnStorageChanges(callback) {
  return useOnStorageEvent('onChange', callback);
}

export function useOnStorageLoad(callback) {
  return useOnStorageEvent('onLoad', callback);
}

export function useOnStorageSave(callback) {
  return useOnStorageEvent('onSave', callback);
}

export function useOnStorageLogin(callback) {
  return useOnStorageEvent('onLogin', callback);
}

export function useOnStorageLogout(callback) {
  return useOnStorageEvent('onLogout', callback);
}

export function useOnResetChapter(callback) {
  return useOnStorageEvent('onReset', callback);
}
